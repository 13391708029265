import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"mb-8 pb-8 gradient"},[_c(VRow,{staticClass:"mt-6 mb-6",attrs:{"justify":"center"}},[_c(VImg,{attrs:{"src":"/static/monplanning.svg","max-width":"300","max-height":"80","contain":""}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"sm":"8"}},[_c('h1',{staticClass:"text-h4 text-center"},[_vm._v(" Essayez monplanning gratuitement ")]),_c('div',{staticClass:"text-h4 text-center font-weight-bold"},[_vm._v(" pendant 30 jours, "),_c('span',{staticClass:"primary--text"},[_vm._v("sans aucune obligation.")])]),_c('div',{staticClass:"mt-3 text-center"},[_vm._v(" Vous êtes professionnel ? Vous désirez découvrir ")]),_c('div',{staticClass:"text-center"},[_vm._v(" comment monplanning peut vous aider à travailler plus efficacement ? ")])])],1)],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"10","lg":"8","xl":"6"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"elevation":"0","color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(_vm.$t('register-title'))}}),_c(VSpacer),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","large":"","target":"_blank","to":"/"}},on),[_c(VIcon,[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("aide monplanning")])])],1),_c(VCardText,[(_vm.message)?_c(VAlert,{attrs:{"type":_vm.successful ? 'success' : 'error'}},[_vm._v(_vm._s(_vm.message))]):_vm._e(),(!_vm.successful)?_c(VForm,{ref:"form"},[_c(VContainer,[_c(VScaleTransition,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.provider == 'email'),expression:"provider == 'email'"}]},[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('first-name', {
                        required: true,
                      }),"name":"firstName","prepend-inner-icon":"mdi-account","type":"text","rules":_vm.$helpers.rule.notEmpty(),"outlined":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1)],1),_c(VScaleTransition,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.provider == 'email'),expression:"provider == 'email'"}]},[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('last-name', {
                        required: true,
                      }),"name":"lastName","prepend-inner-icon":"mdi-account","type":"text","rules":_vm.$helpers.rule.notEmpty(),"outlined":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)],1),_c(VScaleTransition,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.provider == 'email'),expression:"provider == 'email'"}]},[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('email', {
                        required: true,
                      }),"name":"email","prepend-inner-icon":"mdi-email","type":"email","rules":_vm.$helpers.rule.notEmpty(),"outlined":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c(VScaleTransition,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.provider == 'email'),expression:"provider == 'email'"}]},[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('phone-number', {
                        required: true,
                      }),"name":"phoneNumber","type":"phone","prepend-inner-icon":"mdi-phone","rules":_vm.$helpers.rule.notEmpty(),"outlined":""},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})],1)],1),_c(VScaleTransition,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.provider == 'email'),expression:"provider == 'email'"}]},[_c(VTextField,{attrs:{"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$helpers.string.capitalizeI18N('password', {
                        required: true,
                      }),"name":"password","prepend-inner-icon":"mdi-lock","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":_vm.$helpers.rule.notEmpty(),"outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1),_c(VRow,[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('organization-name', {
                      required: true,
                    }),"error":_vm.organizationNameError,"prepend-inner-icon":"mdi-account","type":"text","rules":_vm.$helpers.rule.notEmpty(),"outlined":""},model:{value:(_vm.user.organizationName),callback:function ($$v) {_vm.$set(_vm.user, "organizationName", $$v)},expression:"user.organizationName"}})],1),_c(VRow,{attrs:{"align":"center","justify":"start"}},[_c(VCol,{staticClass:"text-overline",attrs:{"md":"5"}},[_vm._v(" Vous avez un code de parrainage ? ")]),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"hide-details":"","type":"text","outlined":"","dense":""},model:{value:(_vm.user.referralCode),callback:function ($$v) {_vm.$set(_vm.user, "referralCode", $$v)},expression:"user.referralCode"}})],1)],1),_c(VRow,{attrs:{"align":"center","justify":"start"}},[_c(VCol,{staticClass:"text-overline",attrs:{"md":"5"}},[_vm._v(" Vous avez un code promotionnel ? ")]),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"hide-details":"","type":"text","outlined":"","dense":""},model:{value:(_vm.user.promotionalCode),callback:function ($$v) {_vm.$set(_vm.user, "promotionalCode", $$v)},expression:"user.promotionalCode"}})],1)],1),_c(VRow,{staticClass:"mt-8"},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.provider == 'email'),expression:"provider == 'email'"}],attrs:{"color":"primary","disabled":_vm.loading,"block":""},on:{"click":_vm.handleRegister}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-border spinner-border-sm"}),_vm._v(_vm._s(_vm.$t("start-free-trial"))+" ")])],1),_c(VRow,{staticClass:"mt-8"},[_c('small',[_vm._v("* "+_vm._s(_vm.$t("required-fields")))])])],1)],1):_vm._e()],1),(!_vm.successful)?_c(VCardActions,[_c('div',[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"text":"","to":"/admin/login","color":"primary"}},[_vm._v("Vous avez déjà un compte ?")])],1)]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }